/* eslint-disable no-unused-vars */
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, } from 'react';
import {  useParams } from 'react-router-dom';
import { API_URL, REQUEST_METHOD } from 'src/api/constants';
import Container from 'src/components/Container';
import FilterComponents from 'src/components/FilterComponents';
import Table from 'src/components/Table';
import useQuery from 'src/hooks/useQuery';
import { EPRESCREBE_MEDICATION_LIST } from 'src/store/types';

import { decrypt } from 'src/lib/encryption';


const eRxPrescriptionColumns = [
  {
    label: 'Id',
    maxWidth: '6rem',
    dataKey: 'prescribingID',
    type: 'text',
  },
  {
    label: 'Drug Name',
    type: 'text',
    dataKey: 'drugName',
  },
  {
    label: 'Quantity',
    type: 'text',
    dataKey: 'quantity',
  },
  {
    label: 'Dosage Form',
    type: 'text',
    dataKey: 'dosageForm',
  },
  {
    label:'Instruction',
    type: 'text',
    dataKey: 'dosageInstruction',
  },
];
const ERxPrescriptionList = ({applyContainer=true}) => {
  const params = useParams();

  let { patientId } = params || {};
  patientId =decrypt(patientId);

 
 

  const sendToMdToolbox = useCallback(() => {
    const url = 'https://live.mdtoolbox.net/rx/ui/login.aspx';
      // Redirect to the MD Toolbox URL
      window.open(url, '_blank', 'noopener,noreferrer');
    }, []);

  const [
    response,
    loading,
    page,
    rowsPerPage,
    handlePageChange,
    filters,
    handleFilters,
    sort,
    handleSort,
    handleOnFetchDataList,
  ] = useQuery({
    listId: `${EPRESCREBE_MEDICATION_LIST}-${patientId}`,
    url: `${API_URL.mdToolboxSync}/${patientId}/e-prescription`,
    type: REQUEST_METHOD.get,
  });


  
  


  const FilterCollectionHeader = useMemo(
    () =>
      FilterComponents({
        leftComponents: [
          {
            type: 'search',
            filterProps: {
              placeholder: 'Search',
            },
            name: 'searchText',
          },
        ],
        rightComponents: [
          {
            type: 'fabButton',
            style: { ml: 1, minWidth: '38px' },
            actionLabel: 'E-Rx',
            onClick: sendToMdToolbox,
          },
        ],

      }),
    []
  );

  

  return (
    <>
      <Container
        style={{ display: 'flex', flexDirection: 'column' }}
        loading={loading}
        applyContainer={applyContainer}
      >
        <Table
          headerComponent={
            <div>
              <FilterCollectionHeader    onFilterChange={handleFilters}
                filters={filters} />
            </div>
          }
          data={response || []}
          totalCount={response?.totalResults}
          columns={eRxPrescriptionColumns}
          pagination
          rowsPerPage={rowsPerPage}
          page={page}
          handlePageChange={handlePageChange}
          loading={loading}
          sort={sort}
          handleSort={handleSort}
          wrapperStyle={{ boxShadow: 'none', borderRadius: 0 }}
          timezone
        />
    </Container>
    </>
  );
};

export default ERxPrescriptionList;