import { UI_ROUTES } from 'src/lib/routeConstants';
import dashboardIcon from 'src/assets/images/menu/dashboard.png';
import dashboardActiveIcon from 'src/assets/images/menu/dashboardActive.png';
import patientIcon from 'src/assets/images/menu/patient.png';
import patientActiveIcon from 'src/assets/images/menu/patientActive.png';
import bookingIcon from 'src/assets/images/menu/booking.png';
import bookingActiveIcon from 'src/assets/images/menu/bookingActive.png';

import encounterIcon from 'src/assets/images/menu/encounter.png';
import encounterActiveIcon from 'src/assets/images/menu/encounterActive.png';


import messagingIcon from 'src/assets/images/menu/messaging.png';
import messagingActiveIcon from 'src/assets/images/menu/messagingActive.png';

import descriptionIcon from 'src/assets/images/menu/description.png';
import descriptionActiveIcon from 'src/assets/images/menu/descriptionActive.png';

import billingIcon from 'src/assets/images/menu/billing.png';
import billingActiveIcon from 'src/assets/images/menu/billingActive.png';

import settingsIcon from 'src/assets/images/menu/settings.png';
import settingsActiveIcon from 'src/assets/images/menu/settingsActive.png';

import alertIcon from 'src/assets/images/menu/alert.png';
import alertActiveIcon from 'src/assets/images/menu/alertActive.png';

import taskIcon from 'src/assets/images/menu/task.png';
import taskActiveIcon from 'src/assets/images/menu/taskActive.png';

import labIcon from 'src/assets/images/menu/lab.png';
import labActiveIcon from 'src/assets/images/menu/labActive.png';

import formIcon from 'src/assets/images/menu/form.png';
import formActiveIcon from 'src/assets/images/menu/formActive.png';

import faxIcon from 'src/assets/images/menu/fax.png';
import faxActiveIcon from 'src/assets/images/menu/faxActive.png';

const Image =({src,alt='icon',width='24px',height='24px'}={})=> <img src={src} alt={alt} width={width} height={height}/>
const navConfig = [
  {
    key: 'adminDashboard',
    title: 'Dashboard',
    path: UI_ROUTES.adminDashboard,
    icon: <Image src={dashboardIcon} alt="dashboard" />,
    activeIcon: <Image src={dashboardActiveIcon} alt="dashboard" />,
  },
  {
    key: 'patients',
    title: 'Patients',
    path: UI_ROUTES.patient,
    icon: <Image src={patientIcon} alt="patient" />,
    activeIcon: <Image src={patientActiveIcon} alt="patient" />,
    children: [
      {
        key: 'summary',
        title: 'Summary',
        path: UI_ROUTES.patientSummary,
        icon: null,
        activeIcon: null,
      },
      {
        key: 'demographic',
        title: 'Demographic',
        path: UI_ROUTES.editPatient,
        icon: null,
        activeIcon: null,
      },
      {
       key: 'appointment',
       title: 'Appointments',
       path: UI_ROUTES.appointment,
       icon: null,
       activeIcon: null,
      },
      {
        key: 'medications',
        title: 'Medications / E-Rx / EMAR',
        path: UI_ROUTES.patientMedication,
        icon:null,
        activeIcon:null,
      },
      {
       key: 'patientEncounters',
       title: 'Patient Encounters',
       path: UI_ROUTES.patientEncounters,
       icon: null,
       activeIcon: null,
      },
      {
      key: 'allergies',
      title: 'Allergies',
      path: UI_ROUTES.patientAllergies,
      icon:null,
      activeIcon:null,
     },
     {
      key: 'diagnosis',
      title: 'Diagnosis',
      path: UI_ROUTES.patientDiagnosis,
      icon:null,
      activeIcon:null,
     },
     {
      key: 'patientVitals',
      title: 'Vitals',
      path: UI_ROUTES.patientVitals,
      icon:null,
      activeIcon:null,
     },
     {
      key: 'patientHistroy',
      title: 'History',
      path: UI_ROUTES.patientHistory,
      icon:null,
      activeIcon:null,
     },
     {
      key: 'insurance',
      title: 'Insurance',
      path: UI_ROUTES.patientInsurance,
      icon:null,
      activeIcon:null,
     },
     {
      key: 'emergency',
      title: 'Emergency Contact',
      path: UI_ROUTES.emergencyContact,
      icon: null,
      activeIcon: null,
    },
    {
      key: 'labRadiology',
      title: 'Lab/Radiology',
      path: UI_ROUTES.patientLabRadiology,
      icon: null,
      activeIcon: null,
    },
    {
      key: 'documents',
      title: 'Documents',
      path: UI_ROUTES.patientDocuments,
      icon:null,
      activeIcon:null,
     },
     {
      key: 'treatment-plan',
      title: 'Treatment Plan',
      path: UI_ROUTES.patientTreatmentPlans,
      icon:null,
      activeIcon:null,
     },
     {
      key: 'forms',
      title: 'Forms',
      path: UI_ROUTES.patientForms,
      icon:null,
      activeIcon:null,
     },
    ]
  },
  // {
  //   key: 'booking',
  //   title: 'Scheduling',
  //   path: UI_ROUTES.booking,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="url(#paint0_linear_2283_5440)"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="url(#paint1_linear_2283_5440)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5440"
  //           x1="5.49231"
  //           y1="3.75165"
  //           x2="7.92012"
  //           y2="11.0291"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2283_5440"
  //           x1="5.49231"
  //           y1="11.1197"
  //           x2="14.2287"
  //           y2="22.7586"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },

  {
    key: 'board',
    title: 'Board',
    path: UI_ROUTES.board,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
          fill="#9D9D9D"
        />
        <path
          d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
          fill="#9D9D9D"
        />
      </svg>
    ),
    activeIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
          fill="url(#paint0_linear_2283_5440)"
        />
        <path
          d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
          fill="url(#paint1_linear_2283_5440)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2283_5440"
            x1="5.49231"
            y1="3.75165"
            x2="7.92012"
            y2="11.0291"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4461F2" />
            <stop offset="1" stopColor="#2BD9FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2283_5440"
            x1="5.49231"
            y1="11.1197"
            x2="14.2287"
            y2="22.7586"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4461F2" />
            <stop offset="1" stopColor="#2BD9FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    key: 'clinics',
    title: 'Clinics',
    path: UI_ROUTES.clinics,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.9998 5.81616C11.2842 5.81616 10.6096 6.51506 10.1017 7.02294L3.79492 13.3328V20.5659C3.79492 21.3573 4.43773 22.0001 5.23063 22.0001H18.7721C19.5634 22.0001 20.2062 21.3573 20.2062 20.5659V13.3328L13.8979 7.02294C13.3916 6.51506 12.7154 5.81616 11.9998 5.81616ZM16.3767 16.4892H13.4734V19.3925H10.5262V16.4892H7.62145V13.5435H10.5262V10.6387H13.4734V13.5435H16.3767V16.4892Z"
          fill="#9D9D9D"
        />
        <path
          d="M22.2741 10.4006L15.1214 3.25237C13.4522 1.58319 10.5474 1.58319 8.87523 3.25237L1.72702 10.4006C1.27068 10.8569 1.27068 11.5937 1.72702 12.0516C2.16667 12.4912 2.93531 12.4912 3.37497 12.0516L10.5247 4.90033C11.313 4.11198 12.6866 4.11198 13.4734 4.90033L20.6232 12.0516C20.843 12.2714 21.1371 12.3912 21.4464 12.3912C21.7572 12.3912 22.0513 12.2714 22.2741 12.0516C22.729 11.5937 22.729 10.8569 22.2741 10.4006Z"
          fill="#9D9D9D"
        />
      </svg>
    ),
    activeIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.9998 5.81616C11.2842 5.81616 10.6096 6.51506 10.1017 7.02294L3.79492 13.3328V20.5659C3.79492 21.3573 4.43773 22.0001 5.23063 22.0001H18.7721C19.5634 22.0001 20.2062 21.3573 20.2062 20.5659V13.3328L13.8979 7.02294C13.3916 6.51506 12.7154 5.81616 11.9998 5.81616ZM16.3767 16.4892H13.4734V19.3925H10.5262V16.4892H7.62145V13.5435H10.5262V10.6387H13.4734V13.5435H16.3767V16.4892Z"
          fill="url(#paint0_linear_2396_2660)"
        />
        <path
          d="M22.2741 10.4006L15.1214 3.25237C13.4522 1.58319 10.5474 1.58319 8.87523 3.25237L1.72702 10.4006C1.27068 10.8569 1.27068 11.5937 1.72702 12.0516C2.16667 12.4912 2.93531 12.4912 3.37497 12.0516L10.5247 4.90033C11.313 4.11198 12.6866 4.11198 13.4734 4.90033L20.6232 12.0516C20.843 12.2714 21.1371 12.3912 21.4464 12.3912C21.7572 12.3912 22.0513 12.2714 22.2741 12.0516C22.729 11.5937 22.729 10.8569 22.2741 10.4006Z"
          fill="url(#paint1_linear_2396_2660)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2396_2660"
            x1="6.06726"
            y1="8.1815"
            x2="18.6667"
            y2="19.1238"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4461F2" />
            <stop offset="1" stopColor="#2BD9FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2396_2660"
            x1="4.32437"
            y1="3.51913"
            x2="11.3633"
            y2="15.8364"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4461F2" />
            <stop offset="1" stopColor="#2BD9FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
  {
    key: 'clinic',
    title: 'Clinic',
    path: UI_ROUTES.clinic,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.9998 5.81616C11.2842 5.81616 10.6096 6.51506 10.1017 7.02294L3.79492 13.3328V20.5659C3.79492 21.3573 4.43773 22.0001 5.23063 22.0001H18.7721C19.5634 22.0001 20.2062 21.3573 20.2062 20.5659V13.3328L13.8979 7.02294C13.3916 6.51506 12.7154 5.81616 11.9998 5.81616ZM16.3767 16.4892H13.4734V19.3925H10.5262V16.4892H7.62145V13.5435H10.5262V10.6387H13.4734V13.5435H16.3767V16.4892Z"
          fill="#9D9D9D"
        />
        <path
          d="M22.2741 10.4006L15.1214 3.25237C13.4522 1.58319 10.5474 1.58319 8.87523 3.25237L1.72702 10.4006C1.27068 10.8569 1.27068 11.5937 1.72702 12.0516C2.16667 12.4912 2.93531 12.4912 3.37497 12.0516L10.5247 4.90033C11.313 4.11198 12.6866 4.11198 13.4734 4.90033L20.6232 12.0516C20.843 12.2714 21.1371 12.3912 21.4464 12.3912C21.7572 12.3912 22.0513 12.2714 22.2741 12.0516C22.729 11.5937 22.729 10.8569 22.2741 10.4006Z"
          fill="#9D9D9D"
        />
      </svg>
    ),
    activeIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.9998 5.81616C11.2842 5.81616 10.6096 6.51506 10.1017 7.02294L3.79492 13.3328V20.5659C3.79492 21.3573 4.43773 22.0001 5.23063 22.0001H18.7721C19.5634 22.0001 20.2062 21.3573 20.2062 20.5659V13.3328L13.8979 7.02294C13.3916 6.51506 12.7154 5.81616 11.9998 5.81616ZM16.3767 16.4892H13.4734V19.3925H10.5262V16.4892H7.62145V13.5435H10.5262V10.6387H13.4734V13.5435H16.3767V16.4892Z"
          fill="url(#paint0_linear_2396_2660)"
        />
        <path
          d="M22.2741 10.4006L15.1214 3.25237C13.4522 1.58319 10.5474 1.58319 8.87523 3.25237L1.72702 10.4006C1.27068 10.8569 1.27068 11.5937 1.72702 12.0516C2.16667 12.4912 2.93531 12.4912 3.37497 12.0516L10.5247 4.90033C11.313 4.11198 12.6866 4.11198 13.4734 4.90033L20.6232 12.0516C20.843 12.2714 21.1371 12.3912 21.4464 12.3912C21.7572 12.3912 22.0513 12.2714 22.2741 12.0516C22.729 11.5937 22.729 10.8569 22.2741 10.4006Z"
          fill="url(#paint1_linear_2396_2660)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2396_2660"
            x1="6.06726"
            y1="8.1815"
            x2="18.6667"
            y2="19.1238"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4461F2" />
            <stop offset="1" stopColor="#2BD9FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2396_2660"
            x1="4.32437"
            y1="3.51913"
            x2="11.3633"
            y2="15.8364"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4461F2" />
            <stop offset="1" stopColor="#2BD9FF" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },

  // {
  //   key: 'fax',
  //   title: 'Fax',
  //   path: UI_ROUTES.fax,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M20.8479 13.5997L20.4441 13.3609C19.9636 13.0772 19.6765 12.5681 19.6765 12C19.6765 11.4319 19.9636 10.9228 20.4441 10.6391L20.8479 10.4003C21.9501 9.74814 22.327 8.30871 21.6907 7.18043L20.9231 5.81957C20.2883 4.69364 18.8766 4.30707 17.7774 4.95686L17.3737 5.19493C16.8931 5.47936 16.3182 5.47936 15.8384 5.19493C15.3579 4.9105 15.0708 4.40214 15.0708 3.83407V3.35714C15.0708 2.05757 14.0376 1 12.768 1H11.2328C9.96315 1 8.92994 2.05757 8.92994 3.35714V3.83486C8.92994 4.40293 8.64286 4.91129 8.16233 5.19571C7.68181 5.47936 7.10764 5.48014 6.62711 5.19571L6.22335 4.95686C5.12413 4.30707 3.7125 4.69364 3.07691 5.81957L2.3093 7.18043C1.67295 8.30871 2.04985 9.74893 3.15214 10.4003L3.55667 10.6391C4.03719 10.9228 4.32428 11.4319 4.32428 12C4.32428 12.5681 4.03719 13.0772 3.55667 13.3609L3.15291 13.5997C2.05062 14.2511 1.67372 15.6913 2.31007 16.8196L3.07768 18.1804C3.71326 19.3064 5.1249 19.6929 6.22335 19.0431L6.62711 18.8051C7.10764 18.5199 7.68181 18.5214 8.16233 18.8051C8.64286 19.0895 8.92994 19.5979 8.92994 20.1659V20.6429C8.92994 21.9424 9.96315 23 11.2328 23H12.768C14.0376 23 15.0708 21.9424 15.0708 20.6429V20.1651C15.0708 19.5971 15.3579 19.0887 15.8384 18.8043C16.3182 18.5206 16.8931 18.5199 17.3737 18.8043L17.7774 19.0431C18.8766 19.6921 20.2883 19.3056 20.9231 18.1804L21.6907 16.8196C22.327 15.6913 21.9501 14.2511 20.8479 13.5997ZM12.0004 15.9286C9.88408 15.9286 8.16233 14.1662 8.16233 12C8.16233 9.83379 9.88408 8.07143 12.0004 8.07143C14.1167 8.07143 15.8384 9.83379 15.8384 12C15.8384 14.1662 14.1167 15.9286 12.0004 15.9286Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M20.8479 13.5997L20.4441 13.3609C19.9636 13.0772 19.6765 12.5681 19.6765 12C19.6765 11.4319 19.9636 10.9228 20.4441 10.6391L20.8479 10.4003C21.9501 9.74814 22.327 8.30871 21.6907 7.18043L20.9231 5.81957C20.2883 4.69364 18.8766 4.30707 17.7774 4.95686L17.3737 5.19493C16.8931 5.47936 16.3182 5.47936 15.8384 5.19493C15.3579 4.9105 15.0708 4.40214 15.0708 3.83407V3.35714C15.0708 2.05757 14.0376 1 12.768 1H11.2328C9.96315 1 8.92994 2.05757 8.92994 3.35714V3.83486C8.92994 4.40293 8.64286 4.91129 8.16233 5.19571C7.68181 5.47936 7.10764 5.48014 6.62711 5.19571L6.22335 4.95686C5.12413 4.30707 3.7125 4.69364 3.07691 5.81957L2.3093 7.18043C1.67295 8.30871 2.04985 9.74893 3.15214 10.4003L3.55667 10.6391C4.03719 10.9228 4.32428 11.4319 4.32428 12C4.32428 12.5681 4.03719 13.0772 3.55667 13.3609L3.15291 13.5997C2.05062 14.2511 1.67372 15.6913 2.31007 16.8196L3.07768 18.1804C3.71326 19.3064 5.1249 19.6929 6.22335 19.0431L6.62711 18.8051C7.10764 18.5199 7.68181 18.5214 8.16233 18.8051C8.64286 19.0895 8.92994 19.5979 8.92994 20.1659V20.6429C8.92994 21.9424 9.96315 23 11.2328 23H12.768C14.0376 23 15.0708 21.9424 15.0708 20.6429V20.1651C15.0708 19.5971 15.3579 19.0887 15.8384 18.8043C16.3182 18.5206 16.8931 18.5199 17.3737 18.8043L17.7774 19.0431C18.8766 19.6921 20.2883 19.3056 20.9231 18.1804L21.6907 16.8196C22.327 15.6913 21.9501 14.2511 20.8479 13.5997ZM12.0004 15.9286C9.88408 15.9286 8.16233 14.1662 8.16233 12C8.16233 9.83379 9.88408 8.07143 12.0004 8.07143C14.1167 8.07143 15.8384 9.83379 15.8384 12C15.8384 14.1662 14.1167 15.9286 12.0004 15.9286Z"
  //         fill="url(#paint0_linear_2283_5556)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5556"
  //           x1="4.76923"
  //           y1="4.21538"
  //           x2="21.5392"
  //           y2="17.2721"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'home',
  //   title: 'Home',
  //   path: UI_ROUTES.patientHome,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="url(#paint0_linear_2283_5440)"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="url(#paint1_linear_2283_5440)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5440"
  //           x1="5.49231"
  //           y1="3.75165"
  //           x2="7.92012"
  //           y2="11.0291"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2283_5440"
  //           x1="5.49231"
  //           y1="11.1197"
  //           x2="14.2287"
  //           y2="22.7586"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'appointments',
  //   title: 'Appointments',
  //   path: UI_ROUTES.appointments,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="url(#paint0_linear_2283_5440)"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="url(#paint1_linear_2283_5440)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5440"
  //           x1="5.49231"
  //           y1="3.75165"
  //           x2="7.92012"
  //           y2="11.0291"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2283_5440"
  //           x1="5.49231"
  //           y1="11.1197"
  //           x2="14.2287"
  //           y2="22.7586"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'invoice',
  //   title: 'Invoice',
  //   path: UI_ROUTES.invoice,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="url(#paint0_linear_2283_5440)"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="url(#paint1_linear_2283_5440)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5440"
  //           x1="5.49231"
  //           y1="3.75165"
  //           x2="7.92012"
  //           y2="11.0291"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2283_5440"
  //           x1="5.49231"
  //           y1="11.1197"
  //           x2="14.2287"
  //           y2="22.7586"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'chat',
  //   title: 'Chat',
  //   path: UI_ROUTES.chat,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M17 9.50784C17.0001 8.77048 16.8918 8.03711 16.6785 7.3312C16.3758 6.32707 15.8655 5.39762 15.1805 4.60299C14.4956 3.80836 13.6513 3.16626 12.7023 2.71821C11.7533 2.27016 10.7208 2.02616 9.67144 2.00199C8.62212 1.97782 7.5794 2.17402 6.61076 2.57789C5.64212 2.98176 4.76912 3.58432 4.04826 4.34656C3.32739 5.10881 2.77472 6.01377 2.42599 7.00289C2.07726 7.99202 1.94024 9.04328 2.0238 10.0886C2.10737 11.134 2.40965 12.1502 2.91109 13.0715L2.02756 16.17C1.99991 16.267 1.99516 16.369 2.01368 16.4681C2.0322 16.5672 2.07349 16.6606 2.13429 16.7411C2.19509 16.8215 2.27375 16.8868 2.36408 16.9317C2.45441 16.9767 2.55393 17 2.65483 17C2.71523 16.9999 2.77534 16.9915 2.8335 16.9752L5.93531 16.0918C6.3754 16.3328 6.8396 16.5269 7.32027 16.671C8.02667 16.8894 8.76195 17.0003 9.50139 17C11.4895 16.9978 13.3955 16.2077 14.8012 14.8031C16.207 13.3986 16.9978 11.4942 17 9.50784Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M21.9747 21.1574L21.0779 18.0121C21.9938 16.3276 22.2382 14.3592 21.7619 12.5021C21.2855 10.645 20.1238 9.03664 18.51 8C18.7751 9.29611 18.7481 10.635 18.4308 11.9193C18.1135 13.2037 17.514 14.4013 16.6758 15.4253C15.8375 16.4492 14.7816 17.2738 13.5846 17.839C12.3877 18.4043 11.0797 18.6961 9.75585 18.6933C9.16595 18.6915 8.57773 18.6305 8 18.5114C8.69199 19.5766 9.63817 20.4531 10.7534 21.0621C11.8686 21.671 13.1179 21.9933 14.3887 22C15.6536 22.0002 16.8983 21.6834 18.0089 21.0787L21.1566 21.9749C21.2157 21.9914 21.2767 21.9999 21.338 22C21.4404 22 21.5414 21.9763 21.6331 21.9307C21.7248 21.8851 21.8046 21.8189 21.8663 21.7372C21.9281 21.6555 21.97 21.5606 21.9888 21.4601C22.0076 21.3595 22.0027 21.2559 21.9747 21.1574Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M17 9.50784C17.0001 8.77048 16.8918 8.03711 16.6785 7.3312C16.3758 6.32707 15.8655 5.39762 15.1805 4.60299C14.4956 3.80836 13.6513 3.16626 12.7023 2.71821C11.7533 2.27016 10.7208 2.02616 9.67144 2.00199C8.62212 1.97782 7.5794 2.17402 6.61076 2.57789C5.64212 2.98176 4.76912 3.58432 4.04826 4.34656C3.32739 5.10881 2.77472 6.01377 2.42599 7.00289C2.07726 7.99202 1.94024 9.04328 2.0238 10.0886C2.10737 11.134 2.40965 12.1502 2.91109 13.0715L2.02756 16.17C1.99991 16.267 1.99516 16.369 2.01368 16.4681C2.0322 16.5672 2.07349 16.6606 2.13429 16.7411C2.19509 16.8215 2.27375 16.8868 2.36408 16.9317C2.45441 16.9767 2.55393 17 2.65483 17C2.71523 16.9999 2.77534 16.9915 2.8335 16.9752L5.93531 16.0918C6.3754 16.3328 6.8396 16.5269 7.32027 16.671C8.02667 16.8894 8.76195 17.0003 9.50139 17C11.4895 16.9978 13.3955 16.2077 14.8012 14.8031C16.207 13.3986 16.9978 11.4942 17 9.50784Z"
  //         fill="url(#paint0_linear_2283_5460)"
  //       />
  //       <path
  //         d="M21.9747 21.1574L21.0779 18.0121C21.9938 16.3276 22.2382 14.3592 21.7619 12.5021C21.2855 10.645 20.1238 9.03664 18.51 8C18.7751 9.29611 18.7481 10.635 18.4308 11.9193C18.1135 13.2037 17.514 14.4013 16.6758 15.4253C15.8375 16.4492 14.7816 17.2738 13.5846 17.839C12.3877 18.4043 11.0797 18.6961 9.75585 18.6933C9.16595 18.6915 8.57773 18.6305 8 18.5114C8.69199 19.5766 9.63817 20.4531 10.7534 21.0621C11.8686 21.671 13.1179 21.9933 14.3887 22C15.6536 22.0002 16.8983 21.6834 18.0089 21.0787L21.1566 21.9749C21.2157 21.9914 21.2767 21.9999 21.338 22C21.4404 22 21.5414 21.9763 21.6331 21.9307C21.7248 21.8851 21.8046 21.8189 21.8663 21.7372C21.9281 21.6555 21.97 21.5606 21.9888 21.4601C22.0076 21.3595 22.0027 21.2559 21.9747 21.1574Z"
  //         fill="url(#paint1_linear_2283_5460)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5460"
  //           x1="4.07692"
  //           y1="4.19231"
  //           x2="15.7308"
  //           y2="14.1731"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2283_5460"
  //           x1="9.93846"
  //           y1="10.0462"
  //           x2="20.8154"
  //           y2="19.3615"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'shared',
  //   title: 'Shared Notes',
  //   path: UI_ROUTES.shared,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="url(#paint0_linear_2283_5440)"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="url(#paint1_linear_2283_5440)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5440"
  //           x1="5.49231"
  //           y1="3.75165"
  //           x2="7.92012"
  //           y2="11.0291"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2283_5440"
  //           x1="5.49231"
  //           y1="11.1197"
  //           x2="14.2287"
  //           y2="22.7586"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'forms',
  //   title: 'Forms',
  //   path: UI_ROUTES.forms,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M21 8.14286V6.21429C21 5.7028 20.7968 5.21226 20.4351 4.85058C20.0735 4.4889 19.5829 4.28571 19.0714 4.28571H16.5V3.64286C16.5 3.47236 16.4323 3.30885 16.3117 3.18829C16.1912 3.06773 16.0276 3 15.8571 3C15.6866 3 15.5231 3.06773 15.4026 3.18829C15.282 3.30885 15.2143 3.47236 15.2143 3.64286V4.28571H8.78571V3.64286C8.78571 3.47236 8.71798 3.30885 8.59743 3.18829C8.47687 3.06773 8.31335 3 8.14286 3C7.97236 3 7.80885 3.06773 7.68829 3.18829C7.56773 3.30885 7.5 3.47236 7.5 3.64286V4.28571H4.92857C4.41708 4.28571 3.92654 4.4889 3.56487 4.85058C3.20319 5.21226 3 5.7028 3 6.21429V8.14286H21Z"
  //         fill="url(#paint0_linear_2283_5440)"
  //       />
  //       <path
  //         d="M3 9.42847V19.0713C3 19.5828 3.20319 20.0734 3.56487 20.435C3.92654 20.7967 4.41708 20.9999 4.92857 20.9999H19.0714C19.5829 20.9999 20.0735 20.7967 20.4351 20.435C20.7968 20.0734 21 19.5828 21 19.0713V9.42847H3ZM15.6321 13.1307L11.1321 16.9878C11.0091 17.093 10.851 17.1479 10.6893 17.1416C10.5276 17.1353 10.3742 17.0682 10.2598 16.9538L8.33121 15.0252C8.21411 14.9039 8.14932 14.7415 8.15078 14.573C8.15225 14.4044 8.21985 14.2432 8.33905 14.124C8.45824 14.0048 8.61947 13.9372 8.78803 13.9357C8.95658 13.9343 9.11897 13.9991 9.24021 14.1162L10.7484 15.6243L14.7984 12.1529C14.8621 12.0954 14.9367 12.0513 15.0178 12.0232C15.0988 11.995 15.1847 11.9833 15.2703 11.9889C15.3559 11.9944 15.4396 12.0171 15.5163 12.0555C15.5931 12.0939 15.6614 12.1472 15.7172 12.2124C15.773 12.2776 15.8151 12.3533 15.8412 12.4351C15.8673 12.5168 15.8767 12.603 15.869 12.6884C15.8612 12.7739 15.8365 12.8569 15.7961 12.9327C15.7558 13.0084 15.7007 13.0753 15.6341 13.1294L15.6321 13.1307Z"
  //         fill="url(#paint1_linear_2283_5440)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5440"
  //           x1="5.49231"
  //           y1="3.75165"
  //           x2="7.92012"
  //           y2="11.0291"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2283_5440"
  //           x1="5.49231"
  //           y1="11.1197"
  //           x2="14.2287"
  //           y2="22.7586"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'order',
  //   title: 'Orders',
  //   path: UI_ROUTES.order,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="url(#paint0_linear_2283_5448)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5448"
  //           x1="6.03462"
  //           y1="4.56923"
  //           x2="21.3304"
  //           y2="14.862"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'prescription',
  //   title: 'Med Instructions',
  //   path: UI_ROUTES.prescription,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="url(#paint0_linear_2283_5448)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5448"
  //           x1="6.03462"
  //           y1="4.56923"
  //           x2="21.3304"
  //           y2="14.862"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'forms',
  //   title: 'Shared Forms',
  //   path: UI_ROUTES.sharedForms,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="url(#paint0_linear_2283_5448)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5448"
  //           x1="6.03462"
  //           y1="4.56923"
  //           x2="21.3304"
  //           y2="14.862"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'medInstruction',
  //   title: 'Med-Instructions',
  //   path: UI_ROUTES.medInstruction,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="url(#paint0_linear_2283_5448)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5448"
  //           x1="6.03462"
  //           y1="4.56923"
  //           x2="21.3304"
  //           y2="14.862"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   title: 'Form Settings',
  //   path: UI_ROUTES.formBuilder,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M16.5 1.5H4.5C4.30109 1.5 4.11032 1.57902 3.96967 1.71967C3.82902 1.86032 3.75 2.05109 3.75 2.25V18.75C3.75119 19.7442 4.14666 20.6973 4.84966 21.4003C5.55267 22.1033 6.5058 22.4988 7.5 22.5H19.5C19.6989 22.5 19.8897 22.421 20.0303 22.2803C20.171 22.1397 20.25 21.9489 20.25 21.75V5.25C20.2488 4.2558 19.8533 3.30267 19.1503 2.59966C18.4473 1.89666 17.4942 1.50119 16.5 1.5ZM15.75 17.25H8.25C8.05109 17.25 7.86032 17.171 7.71967 17.0303C7.57902 16.8897 7.5 16.6989 7.5 16.5C7.5 16.3011 7.57902 16.1103 7.71967 15.9697C7.86032 15.829 8.05109 15.75 8.25 15.75H15.75C15.9489 15.75 16.1397 15.829 16.2803 15.9697C16.421 16.1103 16.5 16.3011 16.5 16.5C16.5 16.6989 16.421 16.8897 16.2803 17.0303C16.1397 17.171 15.9489 17.25 15.75 17.25ZM15.75 12.75H8.25C8.05109 12.75 7.86032 12.671 7.71967 12.5303C7.57902 12.3897 7.5 12.1989 7.5 12C7.5 11.8011 7.57902 11.6103 7.71967 11.4697C7.86032 11.329 8.05109 11.25 8.25 11.25H15.75C15.9489 11.25 16.1397 11.329 16.2803 11.4697C16.421 11.6103 16.5 11.8011 16.5 12C16.5 12.1989 16.421 12.3897 16.2803 12.5303C16.1397 12.671 15.9489 12.75 15.75 12.75ZM15.75 8.25H8.25C8.05109 8.25 7.86032 8.17098 7.71967 8.03033C7.57902 7.88968 7.5 7.69891 7.5 7.5C7.5 7.30109 7.57902 7.11032 7.71967 6.96967C7.86032 6.82902 8.05109 6.75 8.25 6.75H15.75C15.9489 6.75 16.1397 6.82902 16.2803 6.96967C16.421 7.11032 16.5 7.30109 16.5 7.5C16.5 7.69891 16.421 7.88968 16.2803 8.03033C16.1397 8.17098 15.9489 8.25 15.75 8.25Z"
  //         fill="url(#paint0_linear_2283_5448)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5448"
  //           x1="6.03462"
  //           y1="4.56923"
  //           x2="21.3304"
  //           y2="14.862"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'bookingSetting',
  //   title: 'Settings',
  //   path: UI_ROUTES.bookingSetting,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M20.8479 13.5997L20.4441 13.3609C19.9636 13.0772 19.6765 12.5681 19.6765 12C19.6765 11.4319 19.9636 10.9228 20.4441 10.6391L20.8479 10.4003C21.9501 9.74814 22.327 8.30871 21.6907 7.18043L20.9231 5.81957C20.2883 4.69364 18.8766 4.30707 17.7774 4.95686L17.3737 5.19493C16.8931 5.47936 16.3182 5.47936 15.8384 5.19493C15.3579 4.9105 15.0708 4.40214 15.0708 3.83407V3.35714C15.0708 2.05757 14.0376 1 12.768 1H11.2328C9.96315 1 8.92994 2.05757 8.92994 3.35714V3.83486C8.92994 4.40293 8.64286 4.91129 8.16233 5.19571C7.68181 5.47936 7.10764 5.48014 6.62711 5.19571L6.22335 4.95686C5.12413 4.30707 3.7125 4.69364 3.07691 5.81957L2.3093 7.18043C1.67295 8.30871 2.04985 9.74893 3.15214 10.4003L3.55667 10.6391C4.03719 10.9228 4.32428 11.4319 4.32428 12C4.32428 12.5681 4.03719 13.0772 3.55667 13.3609L3.15291 13.5997C2.05062 14.2511 1.67372 15.6913 2.31007 16.8196L3.07768 18.1804C3.71326 19.3064 5.1249 19.6929 6.22335 19.0431L6.62711 18.8051C7.10764 18.5199 7.68181 18.5214 8.16233 18.8051C8.64286 19.0895 8.92994 19.5979 8.92994 20.1659V20.6429C8.92994 21.9424 9.96315 23 11.2328 23H12.768C14.0376 23 15.0708 21.9424 15.0708 20.6429V20.1651C15.0708 19.5971 15.3579 19.0887 15.8384 18.8043C16.3182 18.5206 16.8931 18.5199 17.3737 18.8043L17.7774 19.0431C18.8766 19.6921 20.2883 19.3056 20.9231 18.1804L21.6907 16.8196C22.327 15.6913 21.9501 14.2511 20.8479 13.5997ZM12.0004 15.9286C9.88408 15.9286 8.16233 14.1662 8.16233 12C8.16233 9.83379 9.88408 8.07143 12.0004 8.07143C14.1167 8.07143 15.8384 9.83379 15.8384 12C15.8384 14.1662 14.1167 15.9286 12.0004 15.9286Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M20.8479 13.5997L20.4441 13.3609C19.9636 13.0772 19.6765 12.5681 19.6765 12C19.6765 11.4319 19.9636 10.9228 20.4441 10.6391L20.8479 10.4003C21.9501 9.74814 22.327 8.30871 21.6907 7.18043L20.9231 5.81957C20.2883 4.69364 18.8766 4.30707 17.7774 4.95686L17.3737 5.19493C16.8931 5.47936 16.3182 5.47936 15.8384 5.19493C15.3579 4.9105 15.0708 4.40214 15.0708 3.83407V3.35714C15.0708 2.05757 14.0376 1 12.768 1H11.2328C9.96315 1 8.92994 2.05757 8.92994 3.35714V3.83486C8.92994 4.40293 8.64286 4.91129 8.16233 5.19571C7.68181 5.47936 7.10764 5.48014 6.62711 5.19571L6.22335 4.95686C5.12413 4.30707 3.7125 4.69364 3.07691 5.81957L2.3093 7.18043C1.67295 8.30871 2.04985 9.74893 3.15214 10.4003L3.55667 10.6391C4.03719 10.9228 4.32428 11.4319 4.32428 12C4.32428 12.5681 4.03719 13.0772 3.55667 13.3609L3.15291 13.5997C2.05062 14.2511 1.67372 15.6913 2.31007 16.8196L3.07768 18.1804C3.71326 19.3064 5.1249 19.6929 6.22335 19.0431L6.62711 18.8051C7.10764 18.5199 7.68181 18.5214 8.16233 18.8051C8.64286 19.0895 8.92994 19.5979 8.92994 20.1659V20.6429C8.92994 21.9424 9.96315 23 11.2328 23H12.768C14.0376 23 15.0708 21.9424 15.0708 20.6429V20.1651C15.0708 19.5971 15.3579 19.0887 15.8384 18.8043C16.3182 18.5206 16.8931 18.5199 17.3737 18.8043L17.7774 19.0431C18.8766 19.6921 20.2883 19.3056 20.9231 18.1804L21.6907 16.8196C22.327 15.6913 21.9501 14.2511 20.8479 13.5997ZM12.0004 15.9286C9.88408 15.9286 8.16233 14.1662 8.16233 12C8.16233 9.83379 9.88408 8.07143 12.0004 8.07143C14.1167 8.07143 15.8384 9.83379 15.8384 12C15.8384 14.1662 14.1167 15.9286 12.0004 15.9286Z"
  //         fill="url(#paint0_linear_2283_5556)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2283_5556"
  //           x1="4.76923"
  //           y1="4.21538"
  //           x2="21.5392"
  //           y2="17.2721"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },
  // {
  //   key: 'masters',
  //   title: 'Masters',
  //   path: UI_ROUTES.masters,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M11 11C13.2091 11 15 9.20914 15 7C15 4.79086 13.2091 3 11 3C8.79086 3 7 4.79086 7 7C7 9.20914 8.79086 11 11 11Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M13.0055 19.8444C12.5535 19.1429 12.6437 18.2788 13.1816 17.6667C12.6437 17.0546 12.5535 16.1912 13.0055 15.4889L13.7073 14.4C14.0933 13.8003 14.8133 13.4278 15.5862 13.4278C15.692 13.4278 15.7961 13.4348 15.8994 13.448C15.928 13.3842 15.9644 13.3243 16 13.2637C15.5316 13.098 15.0258 13 14.4941 13H7.12051C4.84859 13 3 14.6574 3 16.6944V19.4167C3 19.7387 3.29147 20 3.65061 20H13.1052L13.0055 19.8444Z"
  //         fill="#9D9D9D"
  //       />
  //       <path
  //         d="M21.0844 18.3752C21.1011 18.2512 21.1236 18.128 21.1236 18C21.1236 17.8712 21.1003 17.7488 21.0844 17.6248L21.835 17.0792C22.0025 16.9576 22.0491 16.7352 21.9441 16.56L21.2702 15.44C21.1652 15.2656 20.9411 15.1928 20.7479 15.2712L19.884 15.6208C19.6732 15.4664 19.4491 15.3304 19.2017 15.2312L19.0867 14.3504C19.0601 14.1504 18.8835 14 18.6735 14H17.3265C17.1165 14 16.9391 14.1504 16.9133 14.3504L16.7983 15.2312C16.5509 15.3304 16.326 15.4672 16.116 15.6208L15.2521 15.2712C15.0589 15.1928 14.8348 15.2648 14.7298 15.44L14.0559 16.56C13.9509 16.7344 13.9975 16.9568 14.165 17.0792L14.9156 17.6248C14.8989 17.7488 14.8764 17.872 14.8764 18C14.8764 18.1288 14.8997 18.2512 14.9156 18.3752L14.165 18.9208C13.9975 19.0424 13.9509 19.2648 14.0559 19.44L14.7298 20.5608C14.8348 20.7352 15.0589 20.808 15.2521 20.7296L16.116 20.3792C16.3268 20.5336 16.5509 20.6696 16.7983 20.7688L16.9133 21.6496C16.9391 21.8496 17.1157 22 17.3256 22H18.6727C18.8826 22 19.0601 21.8496 19.0859 21.6496L19.2009 20.7688C19.4483 20.6696 19.6732 20.5328 19.8831 20.3792L20.747 20.7288C20.9403 20.8072 21.1644 20.7352 21.2694 20.56L21.9433 19.4392C22.0483 19.2648 22.0016 19.0424 21.8342 18.92L21.0844 18.3752ZM17.9996 19.6C17.079 19.6 16.3335 18.884 16.3335 18C16.3335 17.116 17.079 16.4 17.9996 16.4C18.9201 16.4 19.6657 17.116 19.6657 18C19.6657 18.884 18.9201 19.6 17.9996 19.6Z"
  //         fill="#9D9D9D"
  //       />
  //     </svg>
  //   ),
  //   activeIcon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M11 11C13.2091 11 15 9.20914 15 7C15 4.79086 13.2091 3 11 3C8.79086 3 7 4.79086 7 7C7 9.20914 8.79086 11 11 11Z"
  //         fill="url(#paint0_linear_2394_2542)"
  //       />
  //       <path
  //         d="M13.0055 19.8444C12.5535 19.1429 12.6437 18.2788 13.1816 17.6667C12.6437 17.0546 12.5535 16.1912 13.0055 15.4889L13.7073 14.4C14.0933 13.8003 14.8133 13.4278 15.5862 13.4278C15.692 13.4278 15.7961 13.4348 15.8994 13.448C15.928 13.3842 15.9644 13.3243 16 13.2637C15.5316 13.098 15.0258 13 14.4941 13H7.12051C4.84859 13 3 14.6574 3 16.6944V19.4167C3 19.7387 3.29147 20 3.65061 20H13.1052L13.0055 19.8444Z"
  //         fill="url(#paint1_linear_2394_2542)"
  //       />
  //       <path
  //         d="M21.0844 18.3752C21.1011 18.2512 21.1236 18.128 21.1236 18C21.1236 17.8712 21.1003 17.7488 21.0844 17.6248L21.835 17.0792C22.0025 16.9576 22.0491 16.7352 21.9441 16.56L21.2702 15.44C21.1652 15.2656 20.9411 15.1928 20.7479 15.2712L19.884 15.6208C19.6732 15.4664 19.4491 15.3304 19.2017 15.2312L19.0867 14.3504C19.0601 14.1504 18.8835 14 18.6735 14H17.3265C17.1165 14 16.9391 14.1504 16.9133 14.3504L16.7983 15.2312C16.5509 15.3304 16.326 15.4672 16.116 15.6208L15.2521 15.2712C15.0589 15.1928 14.8348 15.2648 14.7298 15.44L14.0559 16.56C13.9509 16.7344 13.9975 16.9568 14.165 17.0792L14.9156 17.6248C14.8989 17.7488 14.8764 17.872 14.8764 18C14.8764 18.1288 14.8997 18.2512 14.9156 18.3752L14.165 18.9208C13.9975 19.0424 13.9509 19.2648 14.0559 19.44L14.7298 20.5608C14.8348 20.7352 15.0589 20.808 15.2521 20.7296L16.116 20.3792C16.3268 20.5336 16.5509 20.6696 16.7983 20.7688L16.9133 21.6496C16.9391 21.8496 17.1157 22 17.3256 22H18.6727C18.8826 22 19.0601 21.8496 19.0859 21.6496L19.2009 20.7688C19.4483 20.6696 19.6732 20.5328 19.8831 20.3792L20.747 20.7288C20.9403 20.8072 21.1644 20.7352 21.2694 20.56L21.9433 19.4392C22.0483 19.2648 22.0016 19.0424 21.8342 18.92L21.0844 18.3752ZM17.9996 19.6C17.079 19.6 16.3335 18.884 16.3335 18C16.3335 17.116 17.079 16.4 17.9996 16.4C18.9201 16.4 19.6657 17.116 19.6657 18C19.6657 18.884 18.9201 19.6 17.9996 19.6Z"
  //         fill="url(#paint2_linear_2394_2542)"
  //       />
  //       <defs>
  //         <linearGradient
  //           id="paint0_linear_2394_2542"
  //           x1="8.10769"
  //           y1="4.16923"
  //           x2="14.3231"
  //           y2="9.49231"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint1_linear_2394_2542"
  //           x1="4.8"
  //           y1="14.0231"
  //           x2="9.76015"
  //           y2="21.9123"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //         <linearGradient
  //           id="paint2_linear_2394_2542"
  //           x1="15.1077"
  //           y1="15.1692"
  //           x2="21.3231"
  //           y2="20.4923"
  //           gradientUnits="userSpaceOnUse"
  //         >
  //           <stop stopColor="#4461F2" />
  //           <stop offset="1" stopColor="#2BD9FF" />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //   ),
  // },


  {
    key: 'scheduling',
    title: 'Scheduling',
    path: UI_ROUTES.scheduling,
    icon:<Image src={bookingIcon} alt='scheduling'/>,
    activeIcon:<Image src={bookingActiveIcon} alt='scheduling'/>,
  },
 
  {
    key: 'encounters',
    title: 'Encounters',
    path: UI_ROUTES.encounters,
    icon:<Image src={encounterIcon} alt='Encounters'/>,
    activeIcon:<Image src={encounterActiveIcon} alt='Encounters'/>,
  },
  {
    key: 'lab-radiology ',
    title: 'Lab/Radiology ',
    path: UI_ROUTES.labRadiology,
    icon:<Image src={labIcon} alt='Lab/Radiology' width='21px' height='21px' />,
    activeIcon:<Image src={labActiveIcon} alt='Lab/Radiology' width='21px' height='21px' />,
  },
  // {
  //   key: 'lab-radiology-result',
  //   title: 'Lab/Radiology Result ',
  //   path: UI_ROUTES.labRadiologyResult,
  //   icon:<Image src={labIcon} alt='Lab/Radiology' width='21px' height='21px' />,
  //   activeIcon:<Image src={labActiveIcon} alt='Lab/Radiology' width='21px' height='21px' />,
  // },
  {
    key: 'medical-billing',
    title: 'Medical Billing',
    path: UI_ROUTES.medicalBilling,
    icon:<Image src={billingIcon} alt='Encounters'/>,
    activeIcon:<Image src={billingActiveIcon} alt='Encounters'/>,
  },
  {
    key: 'analytics-and-reporting',
    title: 'Analytics and Reporting',
    path: UI_ROUTES.analyticsAndReporting,
    icon:<Image src={descriptionIcon} alt='Analytics and Reporting'/>,
    activeIcon:<Image src={descriptionActiveIcon} alt='Analytics and Reporting'/>,
  },
  {
    key: 'messages',
    title: 'Messages',
    path: UI_ROUTES.messages,
    icon:<Image src={messagingIcon} alt='Messages'/>,
    activeIcon:<Image src={messagingActiveIcon} alt='Messages'/>,
  },
  {
    key: 'alerts',
    title: 'Alerts',
    path: UI_ROUTES.alerts,
    icon:<Image src={alertIcon} alt='Alerts'  width='21px' height='21px'/>,
    activeIcon:<Image src={alertActiveIcon} alt='Alerts'  width='21px' height='21px'/>,
  },
  {
    key: 'tasks',
    title: 'Tasks',
    path: UI_ROUTES.tasks,
    icon:<Image src={taskIcon} alt='Tasks'   width='20px' height='20px'/>,
    activeIcon:<Image src={taskActiveIcon} alt='Tasks'  width='20px' height='20px' />,
  },
  {
    key: 'fax',
    title: 'Fax',
    path: UI_ROUTES.fax,
    icon:<Image src={faxIcon} alt='Fax'   width='20px' height='20px'/>,
    activeIcon:<Image src={faxActiveIcon} alt='Fax'  width='20px' height='20px' />,
  },
  {
    title: 'Form Settings',
    path: UI_ROUTES.formBuilder,
    icon:<Image src={formIcon} alt='Form Settings'   width='21px' height='21px'/>,
    activeIcon:<Image src={formActiveIcon} alt='Form Settings'  width='21px' height='21px' />,
  },
  {
    key: 'staff',
    title: 'Staff',
    path: UI_ROUTES.staff,
    icon: <Image src={patientIcon} alt="staff"  width='21px' height='21px' />,
    activeIcon: <Image src={patientActiveIcon} alt="staff" width='21px' height='21px' />,
  },
  {
    key: 'system-settingss',
    title: 'System Settings',
    path: UI_ROUTES.systemSettings,
    icon:<Image src={settingsIcon} alt='System Settings' width='21px' height='21px'/>,
    activeIcon:<Image src={settingsActiveIcon} alt='System Settings' width='21px' height='21px'/>,
  },

  ////////////////////PATIENT NAV CONFIG//////////////////////////////////////////
  {
    key: 'patient-dashboard',
    title: 'Dashboard',
    path: UI_ROUTES.patientDashboard,
    icon: <Image src={dashboardIcon} alt="dashboard" />,
    activeIcon: <Image src={dashboardActiveIcon} alt="dashboard" />,
  },
  {
    key: 'patient-appointment',
    title: 'Appointments',
    path: UI_ROUTES.patientBooking,
    icon:<Image src={bookingIcon} alt='appointment'/>,
    activeIcon:<Image src={bookingActiveIcon} alt='appointment'/>,
  },
  {
    key: 'patient-forms',
    title: 'Form',
    path: UI_ROUTES.patientSharedForms,
    icon:<Image src={formIcon} alt='Form Settings'   width='21px' height='21px'/>,
    activeIcon:<Image src={formActiveIcon} alt='Form Settings'  width='21px' height='21px' />,
  },
  {
    key: 'patient-medication',
    title: 'Medications',
    path: UI_ROUTES.patientMedicationData,
    icon:<Image src={formIcon} alt='Form Settings'   width='21px' height='21px'/>,
    activeIcon:<Image src={formActiveIcon} alt='Form Settings'  width='21px' height='21px' />,
  },
  {
    key: 'patient-allergy',
    title: 'Allergy',
    path: UI_ROUTES.patientAllergyData,
    icon:<Image src={formIcon} alt='Form Settings'   width='21px' height='21px'/>,
    activeIcon:<Image src={formActiveIcon} alt='Form Settings'  width='21px' height='21px' />,
  },
  {
    key: 'patient-lab-radiology',
    title: 'Lab/Radiology',
    path: UI_ROUTES.patientLabRadiologyData,
    icon:<Image src={labIcon} alt='Lab/Radiology' width='21px' height='21px' />,
    activeIcon:<Image src={labActiveIcon} alt='Lab/Radiology' width='21px' height='21px' />,
  },
  {
    key: 'booking-settings',
    title: 'Booking Settings',
    path: UI_ROUTES.bookingSettings,
    icon:<Image src={settingsIcon} alt='System Settings' width='21px' height='21px'/>,
    activeIcon:<Image src={settingsActiveIcon} alt='System Settings' width='21px' height='21px'/>,
  },
  {
    key: 'patient-messages',
    title: 'Messages',
    path: UI_ROUTES.patientMssages,
    icon:<Image src={messagingIcon} alt='Messages' width='21px' height='21px'/>,
    activeIcon:<Image src={messagingActiveIcon} alt='Messages' width='21px' height='21px'/>,
  },
  ////////////////////////////////////////////////////////////////////////////////


];

export default navConfig;
